<template>
  <v-main >
    <v-row class="ma-0" style="height: 100%">
      <v-col class="pa-0" style="" md="7" lg="7" xl="7">
        <v-img
          style="z-index: 99"
          :src="getImgSrc"
        class="imgClass"></v-img>
      </v-col>
      <v-col class="pa-0" style="background: #f7f7f7" md="5" lg="5" xl="5">
        <div class="px-md-7 mx-md-7 px-lg-8 mx-lg-8 px-xl-15 mx-xl-15 py-15">
          <v-img max-width="189" src="@/assets/Auth/wizfirLogo.svg"></v-img>
          <router-view></router-view>
          <v-img
            style="
              float: right;
              position: absolute;
              bottom: 0px;
              right: 0px;
              z-index: 1;
            "
            max-width="713"
            src="@/assets/Auth/basketShadowAuthSection.svg"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import authToken from "@/common/authToken";
// import { ROUTER_URL } from "@/constants/urls";
import Helper from "@/helper";
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    getImgSrc() {
      console.log('login', '');
      if (this.$route.name === 'forgotPassword') return require("@/assets/Auth/authLayout.svg");
      else if (this.$route.name === 'resetPassword') return require("@/assets/Auth/authLayout.svg");
      else return require("@/assets/Auth/authLayout.svg");
    },
  },
  watch: {},
  methods: {},
  mounted() {
    let userType = authToken.decodedToken().user_type
    if (
      authToken.isCorrectAccessToken &&
      authToken.loginForFirstTime() === true
    ) {
      // Redirect to admin panel
      this.$router.push(
        Helper.getUserPanelUrl(userType)
      );
    }
  },
};
</script>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.imgClass{
height:50%}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.imgClass{
height:100%
}
}
</style>
